<template>
	<div class="page myworkdetail_page">
		<div class="w1200">
			<div class="block20"></div>
			<div class="block20">
				  <el-alert
				    class="larger-el-alert"
				    title="答题期间，切不可关闭页面或退出浏览器."
				    type="warning"
					center
				    :closable="false">
				  </el-alert>
			</div>
			<div class="block30"></div>
			<div class="all_back_typebox" @click="sysgoback()">
				<img class="icon" src="../assets/static/leftgo.png"/>
				<div class="text fontsize16">返回上一页</div>
			</div>
			<div class="block20"></div>
			<div class="myworkdetail_typebox">
				<div class="line"></div>
				<div class="text fontsize32">{{zyssobj.title}}</div>
			</div>
			<div class="block20"></div>
			<div class="topbox">
				<div class="itemlist fontsize16">
					<div class="itemobj">
						<div class="text1">开始时间</div>
						<div class="text2">{{zyssobj.examStartTime}}</div>
					</div>
					<div class="itemobj">
						<div class="text1">类型</div>
						<div class="text2">{{zyssobj.infoTags}}</div>
					</div>
					<div class="itemobj">
						<div class="text1">结束时间</div>
						<div class="text2">{{zyssobj.examEndTime}}</div>
					</div>
					<div class="itemobj">
						<div class="text1">状态</div>
						<div class="text2" v-if="zyssobj.typeTag=='ing'">进行中</div>
						<div class="text2" v-if="zyssobj.typeTag=='end'">已结束</div>
						<div class="text2" v-if="zyssobj.typeTag=='before'">未开始</div>
					</div>
				</div>
			</div>
			<div class="block20"></div>
			<div class="centerbox">
				<div class="typebox fontsize16">
					<div class="itemobj" :class="{'active':typeindex==0}" @click="handletype(0)">
						<div class="text">问题详情</div>
						<div class="line"></div>
					</div>
					<div class="itemobj" :class="{'active':typeindex==1}" @click="handletype(1)">
						<div class="text">题解</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="questionnum">
					<div class="text1 fontsize16">题目序号</div>
					<div class="elpageination">
						<el-pagination
						  background
						  layout="prev, pager, next"
						  @current-change="handlequesChange"
						  :current-page.sync="currentPage"
						  :key="questionindex"
						  :page-size="1"
						  :total="questionlist.length">
						</el-pagination>
					</div>
				</div>
			</div>
			<div class="block20"></div>
			<div class="bottombox" v-if="typeindex==0">
				<div class="leftbox">
					<div class="typebox">
						<div class="line"></div>
						<div class="text fontsize20">{{questionobj.title}}</div>
					</div>
					<div class="block10"></div>
					<!-- <div class="descbox ql-container ql-snow fontsize16">
						<div class="ql-editor">
							<div v-html="questionobj.contents"></div>
						</div>
					</div> -->
					<div class="descbox fontsize16">
						<div class="ql-editor">
							<div v-html="questionobj.contents"></div>
						</div>
					</div>
					<div class="code_box" v-if="classify==0">
						<div class="typename fontsize16">编辑代码</div>
						<div class="textname fontsize14">语言</div>
						<div class="selbox">
							<el-select style="width: 100%;" v-model="selvalue" placeholder="请选择" size="medium">
							    <el-option label="C++" value="C++"></el-option>
							</el-select>
						</div>
						<div class="textname fontsize14">代码</div>
						<div class="textareabox fontsize14">
							<codemirror
							    ref="mycode"
							    v-model="curCode"
							    :options="cmOptions"
							    class="codemirror-container">
							</codemirror>
						</div>
						<div class="btn1" v-if="zyssobj.typeTag=='ing'&&!answerRecord&&testStatus<2" @click="handleceping">提交评测</div>
						<div class="btn1 fontsize14 btn_opacity" v-if="zyssobj.typeTag!='ing'&&!correctSel&&!answerRecord">
							<span v-if="zyssobj.typeTag=='end'">已结束</span>
							<span v-if="zyssobj.typeTag=='before'">未开始</span>
						</div>
					</div>
					<div class="pd_box" v-if="classify==1">
						<div class="itemlist">
							<div class="itemobj" v-for="(item,index) in options" :key="index" @click="handleselanswer(item.key)">
								<div class="imgbox">
									<img v-if="userSel!=item.key" class="img100" src="../assets/static/circle.png"/>
									<img v-if="userSel==item.key" class="img100" src="../assets/static/check-circle-filled.png"/>
								</div>
								<div class="text fontsize16">{{item.key}}.{{item.optionName}}</div>
							</div>
						</div>
						<!-- <div class="btn1 fontsize14" v-if="zyssobj.typeTag=='ing'&&!correctSel&&!answerRecord" @click="handlepullanswer">提交答案</div> -->
						<div class="btn1 fontsize14 btn_opacity" v-if="zyssobj.typeTag!='ing'&&!correctSel&&!answerRecord">
							<span v-if="zyssobj.typeTag=='end'">已结束</span>
							<span v-if="zyssobj.typeTag=='before'">未开始</span>
						</div>
						<div class="resulebox" v-if="correctSel">
							<div class="text_dui fontsize20" v-if="correctSel==userSel">选择正确</div>
							<div class="text_cuo fontsize20" v-if="correctSel!=userSel">选择错误</div>
							<div class="text_res fontsize16">正确答案：{{correctSel}}</div>
						</div>
					</div>
				</div>
				<div class="rightbox">
					<div class="box0">
						<div class="typebox">
							<div class="line"></div>
							<div class="text fontsize20">题目参数</div>
						</div>
						<div class="item fontsize16">
							<div class="text1">时间限制</div>
							<div class="text2">{{questionobj.cpuLimit}}s</div>
						</div>
						<div class="item fontsize16">
							<div class="text1">内存限制</div>
							<div class="text2">{{$util.kbToMB(questionobj.memoryLimit)}}</div>
						</div>
						<div class="item fontsize16">
							<div class="text1">难度大小</div>
							<div class="text2">{{difficultyList[questionobj.difficulty]}}</div>
						</div>
						<div class="btnbox fontsize14">
							<!-- <div class="btn1" v-if="classify==0&&typeindex==0&&!answerRecord" @click="scrollToBottom">提交交卷</div> -->
							<div class="btn1" v-if="testStatus==1" @click="handlejiaojuan">确定交卷</div>
							<div class="btn1 btn_opacity" v-if="testStatus==2">已交卷</div>
							<div class="btn2" @click="handletype(1)">题解</div>
						</div>
					</div>
					<div class="block20"></div>
					<div class="box1 fontsize16">
						<div class="item">
							<img class="avatar" :src="userInfo.hlImage"/>
							<div class="text fontsize16">{{userInfo.nikename}}</div>
						</div>
						<div class="item1">
							<div class="text1">所属题目</div>
							<div class="text4">{{questionobj.title}}</div>
						</div>
						<div class="item1" v-if="zyssobj.score">
							<div class="text1">总得分</div>
							<div class="text4">{{zyssobj.score}}</div>
						</div>
						<!-- <div class="item1" v-if="classify==0&&answerRecord">
							<div class="text1">评测状态</div>
							<div class="text3" v-if="answerRecord.recordStatus==0">Accept</div>
							<div class="text3 texterror" v-if="answerRecord.recordStatus==1">Copmile Error</div>
						</div> -->
						<div class="item1" v-if="classify==0&&answerRecord">
							<div class="text1">评测状态</div>
							<div class="text3" v-if="answerRecord.recordStatus==0">Correct answer</div>
							<div class="texterror" v-if="answerRecord.recordStatus==1">Wrong anwser</div>
						</div>
						<div class="item1" v-if="classify==0&&answerRecord">
							<div class="text1">编译比对</div>
							<div class="text3" v-if="answerRecord.recordStatus==0">比对正确</div>
							<div class="texterror" v-if="answerRecord.recordStatus==1">比对错误</div>
						</div>
						<div class="item1" v-if="classify==0&&answerRecord">
							<div class="text1">评测分数</div>
							<div class="text3" v-if="answerRecord.score>0">{{answerRecord.score}}</div>
							<div class="texterror" v-if="answerRecord.score<=0">0</div>
						</div>
						<div class="item1" v-if="classify==0&&answerRecord">
							<div class="text1">提交时间</div>
							<div class="text2">{{answerRecord.createDate || "暂未提交"}}</div>
						</div>
						<div class="item1" v-if="classify==0&&answerRecord">
							 <el-button type="primary" plain size="small" @click="cpresshow = true">查看测评结果</el-button>
						</div>
					</div>
					<!-- 测评点列表 -->
					<div class="block20" v-if="testStatus==1&&beforeresultsList.length>0"></div>
					<div class="box1 fontsize16 box1fixed" v-if="testStatus==1&&beforeresultsList.length>0">
					    <div class="item1"><div class="text2">评测结果</div></div>
						<div class="item1 pointer" v-for="(item,index) in beforeresultsList" :key="index">
							<div class="text2">测试点{{index+1}}</div>
							<div class="text3" v-if="item.recordStatus==0">正确</div>
							<div class="text5" v-if="item.recordStatus==1">错误</div>
						</div>
					</div>
					<!-- 测评点列表 -->
				</div>
			</div>
			<div class="bottombox_res" v-if="typeindex==1&&quesProblemobj">
				<div class="typebox">
					<div class="line"></div>
					<div class="text fontsize20">{{questionobj.title}}</div>
				</div>
				<div class="videodiv" v-if="quesProblemobj.videoUrl">
					<video class="video" :src="quesProblemobj.videoUrl" controls controlslist="nodownload"></video>
				</div>
				<div class="textbox fontsize206">
					<div class="text" v-html="quesProblemobj.contents"></div>
				</div>
				<div class="block20"></div>
				<div class="typebox" v-if="quesProblemobj.description&&questionobj.classify==0">
					<div class="line"></div>
					<div class="text fontsize20">参考代码</div>
				</div>
				<div class="textareabox fontsize14" v-if="quesProblemobj.description&&questionobj.classify==0">
					<div class="text" v-text="quesProblemobj.description"></div>
				</div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 测评结果显示 -->
		<el-dialog title="测评结果显示" :visible.sync="cpresshow" top="5vh" width="58%">
			<div class="fontsize16">
				<div class="w100" style="margin-bottom: 10px;" v-for="(item,index) in resultsList" :key="index">
					<el-tooltip class="item" effect="dark" :content="item.restip" placement="top">
					    <el-alert v-if="item.recordStatus==0" :title="item.restext" type="success" show-icon :closable="false"></el-alert>
					    <el-alert v-else :title="item.restext" type="error" show-icon :closable="false"></el-alert>
					</el-tooltip>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import { codemirror } from 'vue-codemirror'
import '../common/codesetting.js';
import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
export default {
	components:{
	    codemirror
	},
	data() {
		return {
			uid:null,//社团作业id
			quesid:null,//当前点击的作业题目id
			questionindex:0,//当前题目下标
			currentPage:1,//当前第几题 ，默认第一题
			questionobj:{
				difficulty:0
			},//题目对象
			options:[],//选项
			infoTags:[],//题目类型
			tagcolor:["","success","danger",'warning'],//颜色
			userSel:"",//用户选择
			correctSel:"",//正确选择
			zyssobj:{},//作业详情，
			classify:100,//分类 0编程 1选择
			questionlist:[],//问题列表
			resultlist:[],//完成情况
			answerRecord:null,//答题记录
			resultsobj:{
				exitStatus:200,//0成功，1失败，2超时
			},//测评结果
			resultsList:[],//测评结果集合
			beforeresultsList:[],//交卷前测评结果
			quesProblemobj:null,//题解对象
			typeindex:0,//0问题列表 1详解
			selvalue:"C++",
			curCode:'',//代码输入值
			cmOptions:{
			    mode:'text/x-c++src',
			    theme: "base16-light",
				styleActiveLine: true, // 高亮选中行
				lineNumbers: true, // 显示行号
				lineWrapping: true // 启用自动换行
			},
			cpresshow:false,//测评结果显示
			testStatus:0,//交卷状态，0默认， 1未交卷，2是已交卷
			examRecordList:[],//提交的答题记录
		};
	},
	mounted: function() {
		if(this.isLogin){
			if(this.$route.query&&this.$route.query.uid){
				this.uid = this.$route.query.uid
				if(this.$route.query.index&&Number(this.$route.query.index)>0){
					this.questionindex = Number(this.$route.query.index)
					this.currentPage = Number(this.questionindex + 1)
				}
				//获取社团详情
				this.getgroupDetail()
				// const state = {
				//     key: Math.random() * new Date().getTime()
				// }
				// window.history.pushState(state, null, document.URL)
				// window.addEventListener('popstate', this.handleBackButton);
			}else{
				this.$alert('参数丢失，请返回重试！', '提示', {
				    confirmButtonText: '好的',
				    callback: action => {
						this.sysgoback()
				    }
				});
			}
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole','difficultyList','cepingobj']),
	},
	// beforeDestroy() {
	//     // 一定要在组件销毁前移除事件监听，避免内存泄露
	//     window.removeEventListener('popstate', this.handleBackButton);
	// },
	methods: {
		handleBackButton(event) {
			const state = {
			    key: Math.random() * new Date().getTime()
			}
			window.history.pushState(state, null, document.URL)
			console.log('浏览器默认的返回行为')
			if(!this.answerRecord){
				// 阻止浏览器默认的返回行为
				event.preventDefault();
				this.$alert('答题期间不可离开页面！', '温馨提示', {
				    confirmButtonText: '确定',
				    callback: action => {}
				});
			}
		},
		//得分
		getmyExamUserSumList(){
			var _this = this
			if(this.zyssobj.userGroup){
				//小组作业考试没有得分
				return false;
			}
			var params = {
				userUuid: this.userInfo.uuid,
				examId:this.uid,
			}
			_this.$http.post('app/hlexam/hlQuestExam/myExamUserSumList', params).then(function(res) {
				//数据处理
				hideLoading()
				if (res.code == 200) {
					if(res.data&&res.data.hlQuestExamRecord){
						_this.zyssobj["score"] = res.data.hlQuestExamRecord.score
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//交卷
		handlejiaojuan(){
			var _this = this
			if(this.examRecordList.length<=0){
				//如果不答交卷，完成情况没有记录，无法判断是不是交卷通过的
				this.$alert('题目全部未答，不能提交，请至少答一道！', '温馨提示', {
				    confirmButtonText: '确定',
				    callback: action => {}
				});
				return false;
			}
			//题提交编程题目记录
			var tistext = "确定要提交试卷吗？"
			var synum = this.questionlist.length - this.examRecordList.length
			if(synum>0){
				tistext = "确定要提交试卷吗？您还有"+synum+"道题未答！"
			}
			_this.$confirm(tistext, '提示', {
				confirmButtonText: '确定提交',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				var params = _this.examRecordList
				showLoading()
				_this.$http.post('app/hlexam/hlQuestExamRecord/saveList', params).then(function(res) {
					//数据处理
					hideLoading()
					if (res.code == 200) {
						//_this.$message.success("提交成功！")
						_this.$alert('提交答题成功！', '温馨提示', {
						    confirmButtonText: '确定',
						    callback: action => {}
						});
						//获取自己完成情况
						_this.getByAllExamRecord()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}).catch(() => {});
		},
		//提交测评
		handleceping(){
			// console.log(this.curCode)
			var _this = this
			if(this.curCode){
				var params = {
					codeContent: this.curCode,
					questNo: this.questionobj.questNo
				}
				showLoading()
				this.$http.post('app/hlexam/test/testCode', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						if(res.data){
							//测评鸡返回结果
							_this.resultsList = res.data
							_this.beforeresultsList = res.data
							//保存对象
							// var time = _this.$util.formatdateTime(new Date())
							var params = {
								userUuid: _this.userInfo.uuid,
								examId:_this.uid,
								keys: "",
								questNo: _this.questionobj.questNo,
								userQuestCode: _this.curCode,//用户输入代码
								questTestResponseList:_this.resultsList,
							}
							//查看记录是不是存在并且保存
							_this.setOrgetRecord(params)
						}else{
							_this.$alert('提交测评失败，请稍后重试！', '提示', {
							    confirmButtonText: '确定',
							    callback: action => {
							        
							    }
							});
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}else{
				this.dialogresult = true
				this.resultstate = 2
			}
		},
		//选择题目提交答案
		handlepullanswer(){
			var _this = this
			var params = {
				userUuid: this.userInfo.uuid,
				examId:this.uid,
				keys: this.userSel,
				questNo: this.questionobj.questNo,
			}
			if(this.classify==1){
				if(!this.userSel){
					this.$message.error("请先选择答案选项！")
					return false;
				}
				showLoading()
				this.$http.post('app/hlexam/hlQuestExamRecord/save', params).then(function(res) {
					//数据处理
					hideLoading()
					if (res.code == 200) {
						_this.$message.success("提交成功！")
						_this.correctSel = _this.questionobj.answer
						//获取题目答题记录，有就不给答题了
						//获取自己完成情况
						_this.getByAllExamRecord()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//选择题选择答案
		handleselanswer(key){
			if(this.testStatus == 2){
				//交卷了
				return false;
			}
			if(this.userSel == key){
				return false;
			}
			this.userSel = key
			var params = {
				userUuid: this.userInfo.uuid,
				examId:this.uid,
				keys: this.userSel,
				questNo: this.questionobj.questNo,
				classify: this.questionobj.classify,//分类 0编程 1选择
			}
			//查看记录是不是存在并且保存
			this.setOrgetRecord(params)
		},
		//查看记录是不是存在并且保存
		setOrgetRecord(params){
			var _this = this
			const findindex = _this.examRecordList.findIndex((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.questNo == _this.questionobj.questNo; //筛选出匹配数据
			});
			if(findindex>=0){
				_this.examRecordList[findindex] = params
			}else{
				_this.examRecordList.push(params)
			}
			//console.log(this.examRecordList)
		},
		//选题序号
		handlequesChange(currentPage){
			this.typeindex = 0
			this.currentPage = currentPage
			this.questionindex = Number(currentPage -1)
			this.quesid = this.questionlist[this.questionindex].id
			if(this.testStatus==2){
				//交卷过的
			}else{
				//获取看看是不是答过题
				const obj = this.examRecordList.find((ifitem) => { //这里的options就是上面遍历的数据源
					return ifitem.questNo == this.questionlist[this.questionindex].questNo; //筛选出匹配数据
				});
				if(obj){
					//答过题
					if(obj.classify==1){
						//用户选择的
						this.userSel = obj.keys
						this.curCode = ""
						this.resultsList = []
						this.beforeresultsList = []
					}else{
						//编程题目
						this.userSel = ""
						this.curCode = obj.userQuestCode
						this.resultsList = obj.questTestResponseList
						this.beforeresultsList = obj.questTestResponseList
					}
				}else{
					this.answerRecord = null //答题记录
					this.resultsobj = {
						exitStatus:200,//0成功，1失败，2超时
					} //测评结果
					this.quesProblemobj = null //题解对象
					this.userSel = ""
					this.curCode = ""
					this.resultsList = []
					this.beforeresultsList = []
				}
			}
			//获取题目
			this.gethlQuestInfo()
		},
		//获取详情和获取问题列表
		getgroupDetail(){
			var _this = this 
			var params = {
				examId: this.uid,
				userUuid:this.userInfo.uuid
			}
			this.$http.post('app/hlexam/hlQuestExam/toDetail', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					if(res.data){
						var examStartTime = _this.$util.getDateTime2(res.data.examStartTime)
						var examEndTime = _this.$util.getDateTime2(res.data.examEndTime)
						try{
							res.data["typeTag"] = _this.$util.compareTimes(examStartTime,examEndTime)
						}catch(e){
							res.data["typeTag"] = ""
						}
						//自己的做题情况
						if(res.data.questInfoList){
							res.data.questInfoList.forEach((item, index) => {
								if(!item.hlQuestExamRecordDetail){
									//给默认值
									item.hlQuestExamRecordDetail = {
										recordStatus:-1 //未开始
									}
								}
							});
							_this.questionlist = res.data.questInfoList
							_this.quesid = _this.questionlist[_this.questionindex].id
							//获取完成情况
							_this.getByAllExamRecord()
						}
						_this.zyssobj = res.data
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取自己完成情况
		getByAllExamRecord(){
			var _this = this 
			var params = {
				examId: this.uid,
				userUuid:this.userInfo.uuid
			}
			this.$http.post('app/hlexam/hlQuestExamRecord/getMyQuestExamRecord', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data
					_this.resultlist = records
					if(records.length>0){
						//已交卷
						_this.testStatus = 2
						//得分
						_this.getmyExamUserSumList()
					}else{
						//未交卷
						_this.testStatus = 1
					}
					//没请求过，获取第一个题目
					_this.gethlQuestInfo()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//处理完成情况的记录赋值
		dealRecord(){
			var _this = this 
			const obj = _this.resultlist.find((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.questNo == _this.questionobj.questNo; //筛选出匹配数据
			});
			if(obj){
				_this.userSel = obj.options
				_this.correctSel = obj.answer
				_this.curCode = obj.userQuestCode
				_this.beforeresultsList = []
				if(obj.resultsobj&&obj.resultsobj!='null'){
					try{
						_this.resultsList = JSON.parse(obj.resultsobj)
						_this.beforeresultsList = _this.resultsList
					}catch(e){
						//TODO handle the exception
						_this.resultsList = []
					}
				}else{
					_this.resultsList = []
				}
				var cornum = 0 //正确数量
				//处理逻辑
				_this.resultsList.forEach((resitem, resindex) => {
					const neicun = _this.$util.kbToMB(resitem.testResult.memory / 1024)
					const time = (resitem.testResult.time / 1000000).toFixed(2) + "ms"
					var status = resitem.testResult.status
					if(obj.recordStatus==1){
						status = 'Wrong anwser'
					}
					const fenshu = resitem.recordStatus==0?100:0
					resitem["restip"] = "输出结果：\n" + (resitem.testResult.files.stdout || resitem.testResult.files.stdout)
					resitem["restext"] = '测试点'+(resindex+1)+'：运行内存['+neicun+']，运行时间['+time+']，评测状态['+status+']，分数['+fenshu+']'
				    //正确数量
				    if(resitem.recordStatus==0){
				    	cornum ++
				    }
				});
				if(_this.resultsList.length>0){
					obj["score"] = Number(((cornum / _this.resultsList.length)  * 100).toFixed(0));
				}else{
					obj["score"] = 0
				}
				_this.answerRecord = obj
			}else{
				//未答题
				_this.userSel = ""
				_this.correctSel = _this.questionobj.answer
				_this.curCode = ""
				_this.answerRecord = null
				_this.resultsList = []
				_this.beforeresultsList = []
			}
		},
		//获取题目
		gethlQuestInfo(){
			var _this = this
			//判断有没有缓存过
			const findindex = this.questionlist.findIndex((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.id == _this.quesid; //筛选出匹配数据
			});
			if(findindex>=0){
				if(_this.questionlist[findindex].contents){
					_this.classify = _this.questionlist[findindex].classify
					_this.options = _this.questionlist[findindex].options
					_this.questionobj = _this.questionlist[findindex]
					//已交卷
					if(_this.testStatus == 2){
						//处理完成情况的记录赋值
						_this.dealRecord()
					}
					return false;
				}
			}
			var params = {
				id:this.quesid
			}
			//查询内容
			showLoading()
			this.$http.post('app/hlexam/hlQuestInfo/detail', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					if(res.data){
						_this.classify = res.data.classify
						//获取类型json
						try{
							_this.options = JSON.parse(res.data.options)
							res.data.options = _this.options
						}catch(e){
							_this.options = []
							res.data.options = []
						}
						_this.questionobj = res.data
						if(findindex>=0){
							_this.questionlist[findindex] = res.data
						}else{
							_this.questionlist.push(res.data)
						}
						//已交卷
						if(_this.testStatus == 2){
							//处理完成情况的记录赋值
							_this.dealRecord()
						}
					}else{
						_this.$alert('题目不存在，请返回重试', '提示', {
						    confirmButtonText: '确定',
						    callback: action => {
								_this.sysgoback()
						    }
						});
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//类型
		handletype(type){
			if(type==1&&!this.quesProblemobj){
				//查看题解
				//判断是不是结束或者全部答完题
				// if(this.answerRecord || this.zyssobj.typeTag=='end'){
				// 	this.typeindex = type
				// 	this.handletijie()
				// }else{
				// 	this.$message.info("请先答题！")
				// }
				if(this.testStatus == 2 || this.zyssobj.typeTag=='end'){
					this.typeindex = type
					this.handletijie()
				}else{
					this.$message.info("请先答完交卷！")
				}
			}else{
				this.typeindex = type
			}
		},
		//查看题解
		handletijie(){
			var _this = this
			var params = {
				currentPage: 1,
				pageSize: 1,
				questNo:this.questionobj.questNo || "yuexin"
			}
			showLoading("加载题解中...")
			this.$http.post('app/hlexam/hlQuestExer/index', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					if(res.data.records.length>0){
						_this.quesProblemobj = res.data.records[0]
						_this.$message.success("题解加载成功！")
					}else{
						_this.$alert('抱歉，此题目没题解！', '提示', {
						    confirmButtonText: '确定',
						    callback: action => {
						        
						    }
						});
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
		
	}
};
</script>
<style lang="scss">
	.larger-el-alert .el-alert__title{
		font-size: 16px !important;
	}
</style>
